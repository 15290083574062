import React from 'react';
import { Button, Collapse, Input } from 'antd';
import msg from './messages';
import { SmartRequests } from '../../../utilities';
import MessageChat from './MessageChat';

const { Panel } = Collapse;
const { TextArea } = Input;

class InteractionRequest extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showEvents: false,
        inputMessage: "",
        showButtonDownChat: false,
        
        events: {}
      };
      this.currentIndexMsg = "";
      this.currentIdEvent = "";
      this.ref = React.createRef();
    }

    getEvents(request_id) {
        SmartRequests.get('request/' + request_id + '/events').then( resp => {
            if (resp.status === 200) {
                let events = { ...this.state.events }; 
                events[request_id] = resp.data; 
                this.setState({ events : events }, () => {
                    this.scrollAllMessages();
                })
            }
        })
        
    }

    postComment(request_id) {
        if (this.state.inputMessage !== "") {
            SmartRequests.post('request/' + request_id + '/events', {event:'comment', text: this.state.inputMessage} ).then( resp => {
                this.setState({ inputMessage: "" });
                this.getEvents(this.props.req.id);
            })
        }
    }

    setInputMsg = (event) => {
        this.setState({ inputMessage: event.target.value });
    }

    expandIcon = (event) => {
        if (event[0]) {
            this.getEvents(this.props.req.id);
        }
    }

    scrollAllMessages = () => {
        if (this.ref && this.ref.current) {
            this.ref.current.scrollBy({
                behavior: 'smooth',
                top: this.ref.current.scrollHeight,
            })
        }
    };

    handleScroll = () => {
        if (this.ref) {
            const { scrollTop, clientHeight, scrollHeight } = this.ref.current;
            const showButtonDownChat = scrollTop + clientHeight + 50 < scrollHeight;

            this.setState({showButtonDownChat});
        }
    }

    render(){

        const { req, t, user_id } = this.props;

        let showChat = (req.id in this.state.events) &&  this.state.events[req.id].length > 0;
        let cannotSendMsg = req.state === "closed" || req.state === "cancel";
        let placeholderMsg = cannotSendMsg ? `${t(msg.interactionDisabled)}` : `${t(msg.message)}`;
 
        return (
            <Collapse className='collapse' onChange={(eventCollapse) => this.expandIcon(eventCollapse)}>
                <Panel header={t(msg.deployInteraction)} key="1" >
                    <div className='container-messages-chat' ref={this.ref} onScroll={this.handleScroll}>
                        { showChat && this.state.events[req.id].slice().reverse().map( (event, index) => 
                            <MessageChat key={event.id} event={event} user_id={user_id} index={index} t={t}/>
                        )}
                    </div>

                    <div className='text-chat'>
                        <TextArea  
                            placeholder={placeholderMsg} 
                            value={this.state.inputMessage} 
                            autoSize={{ minRows: 1, maxRows: 4 }}
                            onChange={(event) => this.setInputMsg(event)}
                            style={{borderRadius: 15}}
                            disabled={cannotSendMsg}
                            title={cannotSendMsg ? `${t(msg.interactionIsNotEnabled)}` : ''}
                        />
                        <div className='button-submit'>
                            <Button disabled={cannotSendMsg} shape="circle" icon="right" className='button-send-msg' onClick={() => this.postComment(req.id)}/>
                        </div>

                        {this.state.showButtonDownChat && <Button shape="circle" size="small" icon="down" className='button-go-down-chat' onClick={this.scrollAllMessages}/>}
                        
                    </div>
                </Panel>
            </Collapse>

        )
    }
}

export default InteractionRequest
